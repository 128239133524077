@import '../../../styles/theme-dependencies.scss';

.modalContainer {
  width: 100%;
  max-width: 800px;

  @include custom-breakpoint(800) {
    width: 800px;
  }
}

.title {
  @include fontSize(24);
  font-weight: $font-weight-semibold;
  text-align: center;
}

.form {
  margin-top: 20px;
  padding: 0 32px;
}

.selectedVehicle {
  display: flex;
  align-items: center;
  height: 50px;

  margin-bottom: 10px;
  padding-left: 15px;
  border: 1px solid $gray-border;
  background: $white;
  color: $gray-tert;
}

.textInput {
  margin-bottom: 10px;
  background: $white;
}

.inputWrapper {
  position: relative;
  padding-bottom: 20px;
}

.errorMessage {
  @include fontSize(12);
  position: absolute;
  color: $toyota-red-primary;
  bottom: 15px;
}

.label {
  @include fontSize(14);
  display: inline-block;
  margin: 15px 0;
  font-weight: $font-weight-semibold;
}

.contactOptions {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;

  span {
    @include fontSize(14);
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.radio {
  position: relative;
  width: 25px;
  height: 25px;
  margin-top: 0px;
  -webkit-appearance: none;
  border: 1px solid $gray-border;
  border-radius: 55%;
  outline: none;
  background: $white;
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
    transition: all 0.1s;
    background: $white;
  }

  &:checked {
    border: none;
    background: $toyota-red-primary;

    &::before {
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      border-radius: 55%;
    }
  }
}

.commentBox {
  border: 1px solid $gray-border;
  background-color: $white;
}

.staticComment {
  @include fontSize(12);
  display: block;
  width: 100%;
  padding: 10px;
  background: $white;
  resize: none;
  white-space: pre-line;

  color: $gray-tert;
}

.comment {
  @include fontSize(12);
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  font-family: $font-family-base;
  resize: none;
  outline: none;
}

.disclaimerWrapper {
  @include fontSize(9);
  margin-top: 30px;
  text-align: center;

  @media print {
    margin-top: 0;
  }
}

.disclaimerLink {
  @include fontSize(9);
  display: inline;
  padding: 0;
  width: auto;
}

.ctaContainer {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin: 20px auto;
}

.ctaWrapper {
  width: 132px;

  button {
    text-decoration: none;
    border-radius: 4px;
  }
}
