@import './functions';

// Variables
//
// Shared variables for everything.  If you are thinking of throwing a constant
// value somewhere, you should think of putting it as a variable here instead.
//
// Please keep naming consistent.  Do not camel case variables and keep them
// logically grouped. Also, make sure to use the !default syntax.
//
// You will also notice how the variable naming is similar to Bootstrap naming
// conventions.  The conventions work well, please think about using them.

// Colors

$gray-bg: #f6f6f6;
$blue-bg: #edf0f5;

$white: #fff;
$black: #000;
$black-secondary: #343434;

$gray: #131313;
$gray-secondary: #eee;
$gray-tert: #636466;
$gray-border: #d8d8d8;
$dark-gray: #59595b;

$toyota-red-primary: #e10a1d;
$toyota-red-secondary: #a9141a;
$toyota-red-border: #850004;

$green: #31b445;

// Grid
$grid-max-width: 980px;
$grid-wide: 1440px;
$modal-max-width: 500px;

// Typography
$font-path: '/assets/fonts' !default;
$font-family-sans: 'ToyotaType', -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-mono: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace !default;
$font-family-base: $font-family-sans !default;

$font-size-base: 1rem !default;

$font-weight-normal: 400 !default;
$font-weight-semibold: 600 !default;

$line-height-base: 1.5 !default;

$h1-font-size: calculateRem(40) !default;
$h2-font-size: calculateRem(32) !default;
$h3-font-size: calculateRem(28) !default;
$h4-font-size: calculateRem(24) !default;
$h5-font-size: calculateRem(20) !default;
$h6-font-size: calculateRem(16) !default;

$paragraph-margin-bottom: 1rem !default;

$small-font-size: 80% !default;

//Glyphicons
$icon-font-path: '/assets/glyphicons' !default;
$font-family-glyphicons: 'Glyphicons Halflings' !default;

// Default transition
$trans-timing: 300ms;
$trans-timing-fast: 200ms;
$trans-timing-function: cubic-bezier(0, 0, 1, 1);

$cta-default-height: 50px;
$input-default-height: 50px;

// z-indexes
$loader-index: 101;
$select-input-index: 1;
$modal-index: 1000;
$modal-overlay-index: 999;
