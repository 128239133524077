// http://meyerweb.com/eric/tools/css/reset/
//  v2.0 | 20110126
//  License: none (public domain)

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &::after,
  &::before {
    content: '';
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

// Some additions
// from the classic bootstrap reboot
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

// sass-lint:disable-block class-name-format, property-sort-order, no-vendor-prefixes, force-element-nesting
html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba($black, 0);
  -webkit-text-size-adjust: 100%;
}

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

body {
  margin: 0;
  background-color: $gray-bg;
  color: $black;
  text-align: left;
}

[tabindex='-1'] {
  &:focus {
    outline: 0 !important; // sass-lint:disable-line no-important
  }
}
