@import '../../styles/theme-dependencies.scss';

.container {
  @include grid-container();
  @extend %containerPadding;
  margin-bottom: 30px;
}

.categoriesWrapper {
  margin-top: 35px;
}

.selectWrapper {
  width: 100%;
  margin-top: 20px;

  @include medium-up {
    width: 140px;
    margin-top: 50px;
  }
}
