@import '../../styles/theme-dependencies.scss';

.modal {
  position: fixed;
  z-index: $modal-index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  outline: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
}

.modalDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.overlay {
  position: fixed;
  z-index: $modal-overlay-index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: $black;
}

.dialog {
  display: inline-block;
  z-index: $modal-index;
  width: fit-content;
  height: 100vh;

  @include medium-up() {
    height: 100%;
    padding: 40px 0;
  }
}

.closeBtnWrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

.closeBtn {
  @include fontSize(24);
  position: absolute;
  top: 10px;
  right: 15px;
  border: 0;
  outline: 0;
  background: none;
  color: $toyota-red-primary;
  font-weight: $font-weight-semibold;
  cursor: pointer;

  &::after {
    content: '\00D7';
  }
}

.content {
  position: relative;
  max-height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  outline: 0;
  background-clip: padding-box;
  background-color: $gray-bg;
  box-shadow: 0 5px 15px rgba($black, 0.5);
  overflow: auto;
}
