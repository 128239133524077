@import '../../../styles/theme-dependencies.scss';

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 32px;
  font-family: $font-family-base;

  @include custom-breakpoint(800) {
    width: 800px;
  }

  h1 {
    @include fontSize(32);
    font-weight: $font-weight-normal;
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    text-align: center;
  }

  .cta {
    margin-top: 32px;
    height: 56px;
    width: 112px;
    border-radius: 4px;
    font-weight: $font-weight-semibold;
  }
}
