@import '../../styles/theme-dependencies.scss';

.inputWrapper {
  display: flex;
  align-items: baseline;
  width: 100%;
  height: $input-default-height;
  padding: 0 15px;
  border: 1px solid $gray-border;
  border-radius: 0;
  border-color: none;
  background: $white;
  box-shadow: none;
  color: $gray;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;

  &.invalid {
    border: 1px solid $toyota-red-primary;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.secondary {
    border: none;
    background-color: $white;
  }
}

.prefix {
  @include fontSize(16);
  color: $gray;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

.input {
  @include fontSize(16);
  width: 100%;
  height: 100%;
  border: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
