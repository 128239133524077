@import '../../../styles/theme-dependencies.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;

  form {
    width: 100%;
    max-width: 280px;

    @include medium-up {
      width: auto;
    }
  }
}

.wrapper {
  display: flex;
}

.inputField {
  display: flex;
  justify-content: center;
  width: 180px;

  input {
    padding-top: 5px;
  }
}

.ctaWrapper {
  width: 100px;
  height: $cta-default-height;
}

.message {
  @include fontSize(13);
  width: 250px;
  margin-top: 15px;
  line-height: 1.4;
  text-align: center;

  @include medium-up {
    width: 100%;
  }
}

.hasError {
  @extend %hasError;
}
