@import '../../styles/theme-dependencies.scss';

.headline {
  text-align: center;
}

.title {
  @include fontSize(30);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 40px;

  @include medium-up {
    @include fontSize(34);
    flex-direction: row;
  }
}

.divider {
  display: none;

  @include medium-up {
    display: block;
    align-self: end;
    width: 2px;
    height: 32px;
    margin: 0 10px;
    background-color: $gray-border;
  }
}

.subTitle {
  @include fontSize(16);
  margin-top: 15px;
  color: $gray;
  line-height: 19px;
}
