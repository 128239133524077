@import '../../../../styles/theme-dependencies.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: initial;
  width: 100%;
  margin: 15px auto;
  padding: 20px;
  background: $white;
  cursor: pointer;

  @include small-up {
    flex-direction: row;
    align-items: center;
    width: 450px;
    height: 70px;
  }
}

.radioWrapper {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px solid $gray-border;
  border-radius: 50%;
  cursor: pointer;

  &.isSelected::before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: $toyota-red-primary;
  }
}

.dealerDetail {
  margin-top: 15px;

  @include small-up {
    margin-top: 0;
    margin-left: 20px;
  }
}

.dealerBasicInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include small-up {
    flex-direction: row;
    align-items: center;
  }
}

.name {
  font-weight: $font-weight-semibold;
}

.distance,
.address {
  @include fontSize(12);
  color: $gray-tert;
}

.distance {
  margin: 5px 0;

  @include small-up {
    margin: 0;
    margin-left: 10px;
  }
}
