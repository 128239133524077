/**
Standard font import mixin
**/

@mixin font-import($name, $path, $weight: normal, $style: normal, $svg-name: null, $exts: eot woff2 woff ttf svg) {
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-display: fallback;
    font-weight: $weight;
    src: url($path + '.eot?') format('eot'), url($path + '.eot?#iefix') format('embedded-opentype'), url($path + '.woff') format('woff'), url($path + '.woff2') format('woff2'),
      url($path + '.ttf') format('truetype'), url($path + '.svg#' + if($svg-name != null, $svg-name, $name)) format('svg');
  }
}

@include font-import('ToyotaFontRegular', $font-path + '/ToyotaType/ToyotaType-Regular', normal, $svg-name: 'web');
@include font-import('ToyotaType', $font-path + '/ToyotaType/ToyotaType-Regular', normal, $svg-name: 'web');
@include font-import('ToyotaFontSemibold', $font-path + '/ToyotaType/ToyotaType-Semibold', 600, $svg-name: 'web');
