@import './functions';

@mixin fontSize($size) {
  font-size: calculateRem($size);
}

@mixin custom-breakpoint($breakpoint) {
  @media screen and (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin glyphicon {
  position: relative;
  font-family: $font-family-glyphicons;
  display: inline-block;
  align-items: center;
}
