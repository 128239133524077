@import '../../../styles/theme-dependencies.scss';

.vehicleWrapper {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  min-height: 140px;
  border-bottom: 1px solid $gray-border;

  &:hover {
    cursor: pointer;
  }

  @include medium-up {
    display: initial;
    flex-basis: 33.33%;
    min-height: unset;
    margin-top: 50px;
    padding: 0 40px;
    border: none;
  }

  @include large-up {
    flex-basis: 25%;
  }
}

.imageWrapper {
  flex-basis: 55%;
  padding: 10px;
  text-align: center;

  img {
    width: 100%;
  }

  @include small-up {
    img {
      width: auto;
    }
  }

  @include medium-up {
    flex-basis: 100%;

    img {
      width: 100%;
    }
  }
}

.description {
  @include fontSize(16);
  text-align: left;

  @include medium-up {
    @include fontSize(22);
    margin-left: 0;
    text-align: center;
  }
}
