@import '../../../styles/theme-dependencies.scss';

.container {
  @include grid-container(980);
  @extend %containerPadding;
  padding: 30px 20px 0 20px;
  background-color: $gray-bg;

  @include medium-up {
    padding: 55px 20px 30px 20px;
    border-bottom: 1px solid $gray-border;
  }
}

.disclaimerWrapper {
  @include grid-container(980);
  @extend %containerPadding;
  @include fontSize(9);
  background-color: $gray-bg;
  text-align: center;

  @include medium-down {
    padding-top: 30px;
  }

  .disclaimerTitle {
    font-weight: $font-weight-semibold;
  }

  @media print {
    @include fontSize(11);
    padding-top: 20px;
  }
}

.wrapper {
  @include grid-container(920);
  margin-bottom: 20px;
  padding-top: 0;

  @include medium-up {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 125px;
  }

  @media print {
    margin-bottom: 50px;
  }
}

.imgWrapper {
  display: none;

  @include medium-up {
    display: inline-block;
    width: 215px;
    margin-right: 25px;
  }

  img {
    max-width: 100%;
  }
}

.detailsWrapper {
  display: flex;
  flex: 1;
}

.paymentDetail {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  color: $black-secondary;

  @include medium-up {
    align-items: center;
    padding: 0 15px;
  }

  .content {
    height: 60px;
    text-align: center;
  }

  h4 {
    @include fontSize(34);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    span {
      font-size: inherit;
    }

    sub {
      @include fontSize(16);
      font-weight: $font-weight-normal;
      padding-bottom: 2px;
    }
  }

  span {
    @include fontSize(12);
  }

  .paymentDetailLabel {
    @include fontSize(16);
    display: none;
    position: absolute;
    top: -20px;

    @include medium-up {
      display: block;
    }
  }

  p {
    @include fontSize(9);
    position: absolute;
    top: 90px;
    color: $black-secondary;
    text-align: center;

    @include medium-up {
      top: 70px;
    }
  }
}

.duration {
  flex: 2;
}

.estimated {
  flex: 3;
}

.downPayment {
  flex: 3.5;
}

.perMonth {
  flex: 3.5;
  padding-right: 0;
}

.divider {
  display: none;
  width: 1px;
  height: 60px;
  background-color: $gray-border;

  @include medium-up {
    display: block;
  }
}

.paymentDetail:not(:last-child) {
  display: none;

  @include medium-up {
    display: flex;
  }
}

.ctaWrapper {
  width: 100%;
  max-width: 100%;
  height: 45px;
  margin: 0 auto;

  @media print {
    display: none;
  }

  @include medium-up {
    max-width: 250px;
  }
}

.serieWrapper {
  @include fontSize(20);
  position: relative;
  margin-bottom: 20px;

  @include medium-up {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -13px;
    left: 0;
    width: 90px;
    height: 2px;
    background-color: $toyota-red-primary;
  }
}

.paymentDetailWrapper {
  text-align: center;
}
