@import '../../../styles/theme-dependencies.scss';

.printWrapper {
  display: none;

  @media print {
    display: none;
  }

  .printIcon {
    @include glyphicon;
    @include fontSize(12);
    top: 1px;
    color: $gray-tert;
    margin-right: 5px;

    &::before {
      content: '\e045';
      display: inline-block;
      text-decoration: none;
    }
  }

  @include custom-breakpoint(960) {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
