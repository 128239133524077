@import '../../styles/theme-dependencies.scss';

.container {
  width: 100%;
  padding: 20px 0;
  background-color: $blue-bg;

  @include medium-up {
    background-color: initial;
  }
}

.vehicleDetailsWrapper {
  @include grid-container();
  @extend %containerPadding;
  padding-top: 15px;
  padding-bottom: 0;

  @include medium-up {
    padding-bottom: 20px;
  }
}

.backButtonWrapper {
  position: relative;
  margin-bottom: 25px;
  margin-left: 20px;

  @include x-large-up {
    margin-left: 240px;
  }

  &::after {
    content: '<';
    position: absolute;
    bottom: 0;
    color: $toyota-red-primary;
  }

  @media print {
    display: none;
  }
}
