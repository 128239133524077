@import '../../../styles/theme-dependencies.scss';

.modalContainer {
  width: 100%;
  max-width: 1000px;

  @include custom-breakpoint(1000) {
    width: 1000px;
  }
}

.body {
  padding: 25px;
}

.description {
  font-weight: $font-weight-semibold;
  padding: 10px 0 20px 0;
}

.disclaimerTitle {
  @include fontSize(13);
  padding-bottom: 10px;
}

.disclaimer {
  @include fontSize(13);
  position: relative;
  padding-left: 15px;
}

.disclaimerIndex {
  position: absolute;
  left: 0;
}
