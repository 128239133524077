@import '../../styles/theme-dependencies.scss';

.cta {
  @extend %default;

  &:focus {
    outline: 5px auto Highlight; //Firefox
    outline: 5px auto -webkit-focus-ring-color; // Chrome, Safari
  }
}

.ctaText {
  line-height: 1;
  text-align: center;
}

.primary {
  @extend %primary;
}

.dark {
  @extend %dark;
}

.disabled {
  @extend %disabled;
  display: flex;
  position: relative;
  flex-direction: row;
  font-weight: $font-weight-semibold;
  border: 1px solid $gray-border;
}

.transparent {
  @extend %transparent;
  display: flex;
  position: relative;
  flex-direction: row;
  font-weight: $font-weight-semibold;
}

.downArrow {
  @extend %arrow;
  margin-left: 5px;
}

.secondary {
  @extend %default;
  @include fontSize(12);
  width: auto;
  background-color: transparent;
  color: $gray-tert;
  text-decoration: underline;
}

.inactive {
  background-color: #59595b;

  &:hover,
  &:active,
  &:focus {
    background-color: #3f4041;
  }
}

.isRedColor {
  color: $toyota-red-primary;
}
