@import '../../../styles/theme-dependencies.scss';

.containerFull {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  background-color: $blue-bg;

  @media print {
    padding-top: 0;
  }
}

.container {
  @include fontSize(12);
  @include grid-container();
  @extend %containerPadding;
  padding-top: 20px;

  @media print {
    padding-top: 0;
  }
}

.financialParamsWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 920px;
  margin: 0 auto;

  @include custom-breakpoint(960) {
    flex-flow: row nowrap;
    column-gap: 15px;
  }
}

@include medium-down {
  .singleParamWrapper:nth-child(odd) {
    padding-right: 15px;
  }

  .singleParamWrapper:nth-child(even) {
    padding-left: 15px;
  }
}

.singleParamWrapper {
  position: relative;
  flex-basis: 50%;
  align-self: end;
  width: 100%;
  margin-top: 30px;

  @include medium-up {
    flex-basis: 20%;
    padding: 0 10px;
  }

  @include custom-breakpoint(960) {
    flex-basis: initial;
    margin-top: 0;
    padding: 0;
  }
}

.title {
  position: absolute;
  margin-bottom: 5px;
  white-space: nowrap;

  label {
    display: inline-block;
    position: relative;
  }

  sup {
    @extend %sup;
  }
}

.inputWrapper {
  margin-top: 20px;
  border: none;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  background-color: $gray-border;

  @include medium-up {
    margin-top: 50px;
  }
}

.estimatedTradeInWrapper {
  position: absolute;
  top: 15px;
  right: -125px;
  height: auto;
  padding: 0;

  @include medium-up {
    top: unset;
    right: unset;
    bottom: -33px;
    left: 0;
  }

  @media print {
    display: none;
  }
}
