@import '../../../styles/theme-dependencies.scss';

.navWrapper {
  display: none;

  @include medium-up {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid $gray-border;
  }
}

.category {
  @include fontSize(20);
  position: relative;
  padding: 0;
  color: $black-secondary;

  @include large-up {
    @include fontSize(24);
  }

  &::after {
    @extend %redBottomBorder;
    width: 0;
    transition: all $trans-timing ease-in-out;
  }

  &:hover {
    cursor: pointer;

    &::after {
      width: 100%;
    }
  }
}

.active::after {
  @extend %redBottomBorder;
  width: 100%;
}

.selectInputCategory {
  @include medium-up {
    display: none;
  }
}
