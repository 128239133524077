@import './theme-dependencies';
@import './include/reset';

body {
  background-color: $gray-bg;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;

  &.overlayIsOpen {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-semibold;
}
