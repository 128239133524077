@import '../../../../styles/theme-dependencies.scss';

.offerDetailsWrapper {
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  padding: 15px 0 0 0;
  column-gap: 40px;

  @include medium-up {
    flex-direction: row;
    padding: 50px 20px 0 20px;
  }
}

.checkboxWrapper {
  display: flex;
  position: relative;
}

.checkMark {
  width: 45px;
  height: 45px;
  background-color: $white;
  cursor: pointer;

  &::before {
    content: '';
    visibility: hidden;
    position: absolute;
    top: 16px;
    left: 19px;
    width: 7px;
    height: 13px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid $green;
    border-width: 0 3px 3px 0;
  }

  &.active::before {
    visibility: visible;
  }

  &.disabled {
    background: $gray-border;
    cursor: auto;
  }
}

.checkboxLabel {
  @include fontSize(16);
  margin-left: 5px;
}

.checkboxLabelText {
  margin-left: 10px;
  line-height: 19px;
}

.cta {
  height: auto;
  text-align: left;

  &.disabled {
    cursor: auto;
  }
}
