@import '../../styles/theme-dependencies.scss';

.arrowDown {
  @extend %arrow;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 5px;

  @media print {
    display: none;
  }
}

.arrowUp {
  @extend %arrow;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 5px;
  transform: translateY(-25%) rotate(180deg);
}

.wrapper {
  @include fontSize(16);
  position: relative;
  height: 50px;

  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: none;
    background-color: $white;
  }

  .options {
    visibility: hidden;
    position: absolute;
    z-index: $select-input-index;
    right: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 0;
    overflow: hidden;

    //li
    .item {
      display: flex;
      flex-flow: column nowrap;
      padding: 10px;
      background-color: $white;

      &:hover,
      &:focus {
        background-color: $toyota-red-primary;
        color: $white;
        cursor: pointer;
      }
    }
  }

  &.isOpen {
    .options {
      visibility: visible;
      max-height: 450px;
      overflow: auto;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.secondary {
    @include fontSize(12);
    height: 17px;
    padding-right: 10px;

    .select {
      padding: 0;
      background-color: transparent;
    }

    .arrowUp,
    .arrowDown {
      right: 0;
      border-width: 4px 3px 0 3px;
    }
  }
}
