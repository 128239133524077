@import '../../../styles/theme-dependencies.scss';

.moreDealersBtnWrapper {
  width: fit-content;
  margin: 0 auto;
}

.ctaWrapper {
  width: 250px;
  height: 40px;
  margin: 25px auto;

  @include small-up {
    width: 300px;
  }
}
