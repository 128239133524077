@mixin grid-container($max-width: '') {
  width: 100%;
  max-width: $grid-max-width;
  margin: 0 auto;
  padding-top: 50px;

  @if $max-width != '' {
    max-width: #{$max-width}px;
  }

}

%containerPadding {
  margin: 0;
  padding: 0 25px;

  @include medium-up {
    margin: 0;
    padding: 0 10px;
  }
}
