@import '../../../styles/theme-dependencies.scss';

.vehiclesWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 35px 0;

  @include medium-up {
    margin: 0;
  }
}
