@import '../../../styles/theme-dependencies.scss';

.detailsWrapper {
  @include fontSize(12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .item {
    width: 100%;
    padding: 14px 0;
    text-align: left;

    label {
      display: inline-block;
      position: relative;
    }

    sup {
      @extend %sup;
    }
  }

  .item:not(:last-child) {
    border-bottom: 2px solid $gray-border;
  }

  @include medium-up {
    flex-direction: row;

    .item {
      width: auto;
      padding: 0 10px;
    }

    .item:not(:last-child) {
      border-right: 1px solid $gray-border;
      border-bottom: none;
    }
  }
}
