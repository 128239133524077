@import './variables';

%default {
  @include fontSize(14);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: $cta-default-height;
  padding: 0 10px;
  transition: background-color $trans-timing ease-in-out;
  border: 0;
  outline: none;
  background-color: $white;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

%primary {
  @extend %default;
  background-color: $toyota-red-primary;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    background-color: $toyota-red-secondary;
  }
}

%transparent {
  @extend %default;
  background-color: transparent;
  color: $toyota-red-primary;
}

%disabled {
  @extend %default;
  background-color: transparent;
  color: $gray-tert;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    cursor: unset;
  }
}

%dark {
  @extend %default;
  @include fontSize(14);
  width: auto;
  height: 35px;
  padding: 0 15px;
  padding-bottom: 3px;
  border-radius: 25px;
  background-color: $black;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    background-color: $black;
    color: $toyota-red-primary;
  }
}

%primaryLink {
  @include fontSize(14);
  display: inline-block;
  color: $toyota-red-primary;
  font-weight: $font-weight-semibold;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $toyota-red-secondary;
  }
}

%arrow {
  width: 0;
  height: 0;
  border-width: 6px 5px 0 5px;
  border-style: solid;
  border-color: $toyota-red-primary transparent transparent transparent;
}

%hasError {
  @include fontSize(13);
  margin: 5px 0;
  color: $toyota-red-primary;
}

%redBottomBorder {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 2px;
  background-color: $toyota-red-primary;
}

%sup {
  @include fontSize(9);
  position: absolute;
  top: 0;
  right: -8px;
  color: $toyota-red-primary;
  font-weight: $font-weight-semibold;
  cursor: pointer;
}
